import {  
    Injectable  
} from '@angular/core';  
import {  
    Workbook  
} from 'exceljs';  
import {  
    DatePipe  
} from '@angular/common';  
import * as fs from 'file-saver';  
import * as moment from 'moment'  
@Injectable()  
export class ExcelService {  
     constructor() {}  
    async generateExcel(data, fileData) {  
        const header = ["Complaint No",	"Date",	"Occupant Name","Mobile Number","Quarter Address","Area","Colony","QuarterType","Job Type","Complain Desciption","Work Status","Complain Status","Actual Close Date","Updated At"];  
        // Create workbook and worksheet  
        const workbook = new Workbook();  
        
        const worksheet = workbook.addWorksheet('Complaints'); 
        const title = "CCL, Ranchi";
        const department = "(Town Engineering - All)";
        worksheet.mergeCells('A1:N1');
        worksheet.mergeCells('A2:N2');
        worksheet.mergeCells('A3:N3');
        worksheet.mergeCells('A4:N4');
        worksheet.getCell('A1').value = 'CCL, Ranchi';
        worksheet.getCell('A2').value = '(Town Engineering - '+fileData.department+')';
        worksheet.getCell('A2').alignment = { horizontal:'center'} ;
        worksheet.getCell('A3').value = fileData.dateFilter;
        worksheet.getCell('A4').value = '';
        ['A1', 'A2', 'A3', 'A4'].map(key => {
            worksheet.getCell(key).font = {
                bold: true,
            };
            worksheet.getCell(key).alignment = { horizontal:'center'};
            worksheet.getCell(key).border = {  
                top: {  
                    style: 'thin'  
                },  
                left: {  
                    style: 'thin'  
                },  
                bottom: {  
                    style: 'thin'  
                },  
                right: {  
                    style: 'thin'  
                }  
            };  
        });
        // Cell Style : Fill and Header  
        var TodayDate = new Date();  
        let MMDDYY = moment(TodayDate).format('MMDDYY').toString();  
        var FileName = "Complain_Report_" + MMDDYY;  
        // let letterhead = workbook.addRow();

        const headerRow = worksheet.addRow(header);  
        headerRow.eachCell((cell, number) => {  
            cell.fill = {  
                type: 'pattern',  
                pattern: 'solid',  
                fgColor: {  
                    argb: 'FFFFFFFF'  
                },  
                bgColor: {  
                    argb: 'FFFFFFFF'  
                },  
            };  
            cell.font = {  
                color: {  
                    argb: '00000000',  
                },  
                bold: true  
            }  
            cell.border = {  
                top: {  
                    style: 'thin'  
                },  
                left: {  
                    style: 'thin'  
                },  
                bottom: {  
                    style: 'thin'  
                },  
                right: {  
                    style: 'thin'  
                }  
            };  
        });  
        data.forEach(d => {  
            const row = worksheet.addRow(d);  
            row.fill = {  
                type: 'pattern',  
                pattern: 'solid',  
                fgColor: {  
                    argb: 'FFFFFFFF'  
                }  
            };  
            row.font = {  
                color: {  
                    argb: '00000000',  
                },  
                bold: false  
            }  
            row.eachCell({ includeEmpty: true },(cell, number) => {  
                cell.border = {  
                    top: {  
                        style: 'thin'  
                    },  
                    left: {  
                        style: 'thin'  
                    },  
                    bottom: {  
                        style: 'thin'  
                    },  
                    right: {  
                        style: 'thin'  
                    }  
                };  
                cell.alignment = { vertical: 'middle', wrapText: true };
            });  
        });  
        worksheet.columns.forEach(function (column, i) {
            column.width = 20;
        });
        console.log(worksheet)
        workbook.xlsx.writeBuffer().then((data: any) => {  
            const blob = new Blob([data], {  
                type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'  
            });  
            fs.saveAs(blob, fileData.fileName + '.xlsx');  
        });  
    }  
}  