import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantService } from './constant.service';

@Injectable()
export class EnquiryService {
    DEPARTMENT = [];


    constructor(public http: HttpClient, private constant: ConstantService) {

    }
    addEnquiry(payload) {
        return this.http.post(this.constant.BASE_URL + this.constant.GENERAL_ENQUIRY, payload);
    }
    updateEnquiry(payload, srl_no) {
        return this.http.put(this.constant.BASE_URL + this.constant.GENERAL_ENQUIRY+ 'srl_no/'+ srl_no + "/", payload);
    }
    listEnquiry() {
        return this.http.get(this.constant.BASE_URL + this.constant.GENERAL_ENQUIRY);
    }

    listEnquiryBySrlNo(srl_no) {
        return this.http.get(this.constant.BASE_URL + this.constant.GENERAL_ENQUIRY+ 'srl_no/'+ srl_no + "/");
    }
    
    deleteGeneralEnquiry(ids) {
        let idsArray = '('+ids.join(',')+ ')';
        console.log(this.constant.BASE_URL + this.constant.GENERAL_ENQUIRY+ 'srl_no/'+ idsArray + "/");
        return this.http.delete(this.constant.BASE_URL + this.constant.GENERAL_ENQUIRY+ 'srl_no/'+ btoa(idsArray) + "/");
    }
}