import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantService } from './constant.service';

@Injectable()
export class LoginService {

    constructor(public http: HttpClient, private constant: ConstantService) {

    }

    login(payload) {
        return this.http.post(this.constant.BASE_URL + this.constant.LOGIN_URL, payload);
    }

    resetPassword(emailid) {
        return this.http.get(this.constant.BASE_URL + this.constant.RESET_PASSWORD_URL + 'email_id/'+ emailid + '/');
    }
}