import { INavData } from '@coreui/angular';

export const navItems: INavData[] = [
  {
    name: 'Dashboard',
    url: '/views/dashboard',
    icon: 'icon-speedometer',
    class: 'admin'
  },
  {
    name: 'Dashboard',
    url: '/views/dashboard',
    icon: 'icon-speedometer',
    class: 'department'
  },
  {
    name: 'Grievance',
    url: '/views/covid',
    icon: 'fa fa-list',
    class: 'admin',
    children: [
      {
        name: 'New Grievance',
        url: '/views/grievance/newGrievance',
        icon: 'fa fa-send-o'
      },
      {
        name: 'Grievance List',
        url: '/views/grievance/offlineGrievance',
        icon: 'icon-call-in'
      },
      {
        name: 'Update Status',
        url: '/views/complain/complaintStatus',
        icon: 'fa fa-globe'
      }
    ]
  },
  {
    name: 'General Enquiry',
    url: '/views/inquiry',
    icon: 'fa fa-list',
    class: 'admin',
    children: [
      {
        name: 'New Enquiry',
        url: '/views/inquiry/newInquiry',
        icon: 'fa fa-send-o'
      },
      {
        name: 'Grievance List',
        url: '/views/inquiry/inquiryList',
        icon: 'icon-call-in'
      }
    ]
  },
  {
    name: 'Grievance',
    url: '/views/covid',
    icon: 'fa fa-list',
    class: 'department',
    children: [
      {
        name: 'Grievance List',
        url: '/views/grievance/departmentGreivance',
        icon: 'icon-call-in'
      }
    ]
  },
  {
    name: 'Setting',
    url: '/views/setting',
    icon: 'fa fa-gears',
    class: 'admin',
    children: [
      {
        name: 'Wards',
        url: '/views/setting/wards',
        icon: 'fa fa-home'
      },
      {
        name: 'Grievance Type',
        url: '/views/setting/grievance_type',
        icon: 'fa fa-home'
      },
      {
        name: 'Grievance Sub Type',
        url: '/views/setting/grievance_sub_type',
        icon: 'fa fa-home'
      },
      {
        name: 'User',
        url: '/views/setting/user',
        icon: 'fa fa-home'
      }
    ]
  },
  {
    name: 'Logout',
    url:'/logout',
    icon: 'fa fa-power-off',
    class: 'admin'
  },
  {
    name: 'Logout',
    url:'/logout',
    icon: 'fa fa-power-off',
    class: 'department'
  },
];
