import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

import { AppComponent } from './app.component';

// Import containers
import { DefaultLayoutComponent } from './containers';

import { P404Component } from './views/error/404.component';
import { P500Component } from './views/error/500.component';
import { LoginComponent } from './views/login/login.component';
import { RegisterComponent } from './views/register/register.component';

const APP_CONTAINERS = [
  DefaultLayoutComponent
];

import {
  AppAsideModule,
  AppBreadcrumbModule,
  AppHeaderModule,
  AppFooterModule,
  AppSidebarModule,
} from '@coreui/angular';

// Import routing module
import { AppRoutingModule } from './app.routing';

// Import 3rd party components
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { ChartsModule } from 'ng2-charts';
import { FormsModule } from '@angular/forms';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import {LoginService} from './services/login.service';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { Interceptor } from './services/interceptor.service';
import { ConstantService } from './services/constant.service';
import { NgxCsvParserModule } from 'ngx-csv-parser';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';
import { PapaParseModule } from 'ngx-papaparse';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { DataloaderComponent } from './directive/dataloader.component';
import { LoaderService } from './services/loader.service';
import { AgGridModule } from 'ag-grid-angular';
import { AgmCoreModule } from '@agm/core';
import { CovidService } from './services/covid.service';
import { ContactsService } from './services/contacts.service';
import { AlertModule } from 'ngx-bootstrap/alert';
import { EditButtonRendererComponent } from './renderer/edit-button-renderer.component';
import { DeleteButtonRendererComponent } from './renderer/delete-button-renderer.component';
import { FeedbackButtonRendererComponent } from './renderer/feedback-button-renderer.component';
import { ViewFeedbackButtonRendererComponent } from './renderer/view-feedback-button-renderer.component';
import { ProfileService } from './services/profile.service';
import { GrievanceService } from './services/grievance.services';
import { NewsService } from './services/news.service';
import { McpService } from './services/mcp.service';
import { EnquiryService } from './services/enquiry.service';
import { ValidateCsvService } from './services/validateCsv.service';
import { ExcelService } from './services/export-excel.service';
@NgModule({
  imports: [
    BrowserModule,
    FormsModule,
    OwlDateTimeModule, 
    OwlNativeDateTimeModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppAsideModule,
    AppBreadcrumbModule.forRoot(),
    AppFooterModule,
    AppHeaderModule,
    AppSidebarModule,
    PerfectScrollbarModule,
    BsDropdownModule.forRoot(),
    TabsModule.forRoot(),
    ChartsModule,
    HttpClientModule,
    NgxCsvParserModule,
    ProgressbarModule.forRoot(),
    PapaParseModule,
    TypeaheadModule.forRoot()
  ],
  declarations: [
    AppComponent,
    ...APP_CONTAINERS,
    P404Component,
    P500Component,
    LoginComponent,
    RegisterComponent,
    // MultiSelectComponent
    DataloaderComponent,
  ],
  entryComponents: [
  ],
  providers: [{
    provide: LocationStrategy,
    useClass: HashLocationStrategy,
  },
  {
      provide: HTTP_INTERCEPTORS,
      useClass: Interceptor,
      multi: true
  },
  LoginService,
  ConstantService,
  ContactsService,
  CovidService,
  LoaderService,
  ProfileService,
  GrievanceService,
  NewsService,
  McpService,
  EnquiryService,
  ValidateCsvService,
  ExcelService
],
  bootstrap: [ AppComponent ]
})
export class AppModule { }
