import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantService } from './constant.service';

@Injectable()
export class ProfileService {
    DEPARTMENT = [];


    constructor(public http: HttpClient, private constant: ConstantService) {

    }
    getDepartment() {
        return this.DEPARTMENT;
    }
    setDepartment() {
        if (this.DEPARTMENT.length === 0) {
            let that = this;
            this.listDepartment().subscribe((departResp: []) => {
                that.DEPARTMENT = departResp;
                console.log(that.DEPARTMENT);
            })
        }
    }

    createProfile(payload) {
        return this.http.post(this.constant.BASE_URL + this.constant.PROFILE, payload);
    }

    updateProfile(srl_no, payload) {
        return this.http.put(this.constant.BASE_URL + this.constant.PROFILE + 'srl_no/' +  srl_no + '/', payload);
    }
    deleteProfile(srl_no) {
        return this.http.delete(this.constant.BASE_URL + this.constant.PROFILE + 'srl_no/' +  srl_no + '/');
    }
    deleteProfileByUsername(username) {
        return this.http.delete(this.constant.BASE_URL + this.constant.PROFILE + 'username/' +  username + '/');
    }
    listProfile() {
        return this.http.get(this.constant.BASE_URL + this.constant.PROFILE);
    }
    listDepartment() {
        return this.http.get(this.constant.BASE_URL + this.constant.DEPARTMENT);

    }
    listProfileById(id) {
        return this.http.get(this.constant.BASE_URL + this.constant.PROFILE + 'srl_no/' + id + '/');
    }

    //---------------------ICDS Profiles Manage
    createICDSProfile(payload) {
        return this.http.post(this.constant.BASE_URL + this.constant.ICDS, payload);
    }
    updateICDSProfile(srl_no, payload) {
        return this.http.put(this.constant.BASE_URL + this.constant.ICDS + 'srl_no/' +  srl_no + '/', payload);
    }
    deleteICDSProfile(srl_no) {
        return this.http.delete(this.constant.BASE_URL + this.constant.ICDS + 'srl_no/' +  srl_no + '/');
    }
    listICDSProfile() {
        return this.http.get(this.constant.BASE_URL + this.constant.ICDS);
    }

    //---------------------Health Clinics Profiles Manage
    createClinicProfile(payload) {
        return this.http.post(this.constant.BASE_URL + this.constant.CLINICS, payload);
    }
    updateClinicProfile(srl_no, payload) {
        return this.http.put(this.constant.BASE_URL + this.constant.CLINICS + 'srl_no/' +  srl_no + '/', payload);
    }
    deleteClinicProfile(srl_no) {
        return this.http.delete(this.constant.BASE_URL + this.constant.CLINICS + 'srl_no/' +  srl_no + '/');
    }
    listClinicProfile() {
        return this.http.get(this.constant.BASE_URL + this.constant.CLINICS);
    }
}
