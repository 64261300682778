import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { promise } from 'protractor';
import { rejects } from 'assert';

@Injectable()
export class ConstantService {
    PROTOCOL = location.protocol;
    BASE_URL = location.protocol + environment.api_base_url;
    LOGIN_URL = '/loginValidate/';
    RESET_PASSWORD_URL = '/reset_password/';
    CONTROLLERS = 'controllers/'
    COVID_NEW_PATIENT = this.CONTROLLERS + '/covid_patient_detail/';
    COVID_NEGATIVE_PATIENT = this.CONTROLLERS + '/covid_ra_negative/';
    COVID_NEW_PATIENT_WITH_FEEDBACK = this.CONTROLLERS + '/covid_patient_detail2/';
    COVID_PATIENT_FEEDBACK = this.CONTROLLERS + '/covid_patient_feedback/';
    GRIEVANCE = this.CONTROLLERS + '/petition/';
    GRIEVANCE_FEEDBACK = this.CONTROLLERS + '/petition_feedback/';
    CUSTOM_REPORT = 'customSearch/';
    AWW = this.CONTROLLERS + '/aww/';
    ASHA_WORKER = this.CONTROLLERS + '/asha_worker/';
    BPMU_STAFF = this.CONTROLLERS + '/bpmu_staff/';
    EMERGENCY_CONTACTS = this.CONTROLLERS + '/emergency_contacts/';
    WDC_STAFF = this.CONTROLLERS + '/lady_supervisior_wdc/';
    PROFILE = this.CONTROLLERS + '/users/';
    ICDS = this.CONTROLLERS + '/icds_detail/';
    CLINICS = this.CONTROLLERS + '/clinic_detail/';
    DEPARTMENT = this.CONTROLLERS + '/grievance_type/';
    SMS = '/smsBalance/';
    NEWS_UPLOAD_PATH = '/files/news.php';
    NEWS = this.CONTROLLERS + '/news/';
    MCP = this.CONTROLLERS + '/mcp/';
    MCP_MOTHER_DATA = this.CONTROLLERS + '/quaters/';
    GENERAL_ENQUIRY = this.CONTROLLERS + '/general_enquiry/';
    COOKIES = {
        TOKEN: '',
        PERMISSION: '',
        USERNAME: '',
        ID: '',
        USERTYPE:'',
        DEPARTMENT:'',
        WARDS: ''
    }



    BLOCKS = ['Balisankara', 'Bargaon', 'Bisra', 'Bonai', 'Gurundia', 'Hemgir', 'Koida',
     'Kuarmunda', 'Kutra', 'Lahunipada', 'Lathikata', 'Lephripada', 'Nuagaon', 'Rajgangpur', 'Subdega', 'Sundargarh', 'Tangarpalli'];

    getCookies() {
        if (sessionStorage.token) {
            let cookies = atob(sessionStorage.token);
            let data = cookies.split('@');
            // console.log(data);
            this.COOKIES = {
                TOKEN: data[0],
                PERMISSION: atob(data[2]),
                USERNAME: atob(data[1]),
                ID: atob(data[3]),
                USERTYPE: atob(data[6]),
                DEPARTMENT:atob(data[5]),
                WARDS: atob(data[7])
            }
        }
        // console.log(this.COOKIES)
        return this.COOKIES;
    }
    constructor(private http: HttpClient) {}

    getCount(queryString) {
        return new Promise((resolve, rejects) => {
            this.http.get(this.BASE_URL + this.CUSTOM_REPORT + queryString + '/').subscribe((report: any) => {
                resolve(report);
            })
        })
    }

    getSMSBalance() {
        return new Promise((resolve) => {
            this.http.get(this.BASE_URL + this.SMS).subscribe((resp) => {
                console.log(resp);
                resolve(resp);
            })
        })
    }


}
