import {Component, OnInit} from '@angular/core';
import { navItems } from '../../_nav';
import { ProfileService } from '../../services/profile.service';
import { ConstantService } from '../../services/constant.service';
import * as _ from 'lodash';

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.css']
})
export class DefaultLayoutComponent implements OnInit {
  public sidebarMinimized = false;
  public navItems = navItems;
  public userType: string = '';
  constructor(private constant: ConstantService) {
    	this.userType = this.constant.getCookies().USERTYPE;
      if (this.userType === 'department') {
        this.navItems = _.filter(this.navItems, (i: any) => {return  i.class === 'department';} );
      } else {
        this.navItems = _.filter(this.navItems, (i: any) => {return  i.class !== 'department';} );
      }
      console.log(this.navItems)
  }

  ngOnInit() {
  }

  toggleMinimize(e) {
    this.sidebarMinimized = e;
  }
}
