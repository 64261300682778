import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantService } from './constant.service';

@Injectable()
export class ValidateCsvService {
    constructor(public http: HttpClient, private constant: ConstantService) {

    }

    isCsvEmpty(result) {
        let response = {
            
        }
        if(result && result.meta && result.meta.fields) {

        } else {

        }
    }

    validateCsvHeader(defienedHeader, csvHeader) {

    }
}