import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderState } from './loader';
@Injectable({
    providedIn: 'root'
})
export class LoaderService {
    private loaderSubject = new Subject<LoaderState>();
    loaderState = this.loaderSubject.asObservable();
    private dataLoaderSubject = new Subject<LoaderState>();
    dataLoaderState = this.dataLoaderSubject.asObservable();
    constructor() { }
    show() {
        this.loaderSubject.next(<LoaderState>{ show: true });
    }
    hide() {
        this.loaderSubject.next(<LoaderState>{ show: false });
    }
    
    showDataLoader() {
        this.dataLoaderSubject.next(<LoaderState>{ show: true });
    }
    hideDataLoader() {
        this.dataLoaderSubject.next(<LoaderState>{ show: false });
    }
}