import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantService } from './constant.service';

@Injectable()
export class GrievanceService {

    selectedDateRange = [];
    
    getSelectedDateRange() {
        return this.selectedDateRange;
    }

    setSelectedDateRange(dateRange) {
        this.selectedDateRange = dateRange;
    }


    constructor(public http: HttpClient, private constant: ConstantService) {

    }

    addNewGrievance(payload) {
        return this.http.post(this.constant.BASE_URL + this.constant.GRIEVANCE, payload);
    }
    getGrievanceDetail(srl_no) {
        return this.http.get(this.constant.BASE_URL + this.constant.GRIEVANCE + 'srl_no/' + srl_no + '/');
    }
    
    getGrievanceFeedback(srl_no) {
        return this.http.get(this.constant.BASE_URL + this.constant.GRIEVANCE_FEEDBACK + 'grievance_id/' + srl_no + '/' );
    }
    
    addNewGrievanceFeedback(payload) {
        return this.http.post(this.constant.BASE_URL + this.constant.GRIEVANCE_FEEDBACK, payload);
    }
    
    updateGreivanceStatus(srl_no, payload) {
        return this.http.put(this.constant.BASE_URL + this.constant.GRIEVANCE+ 'srl_no/"' + srl_no + '"/', payload);
    }
}