import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { Subscription } from 'rxjs';
import {LoaderService} from "../services/loader.service";
import {LoaderState} from "../services/loader";

@Component({
    selector: 'app-data-loader',
    template: `
        <div>
            <div class="loaderMain"  *ngIf="type === 'text' && show === true">
                <div [ngClass]="{'loaderBox' : showBg !== 'false'}">
                  <div class="loader">
                     <div class="text small">Fetching records</div>
                     <div class="dots">
                           <div></div>
                           <div></div>
                           <div></div>
                           <div></div>
                     </div>
                  </div>
                </div>
            </div>
        </div>
    `,
    styles: [
      
        `
        .loaderMain {
         display: flex;
         flex-direction: row;
         justify-content: center;
         align-items: center;
         overflow: hidden;
         font-family: "Roboto", sans-serif;
         height: 50px;
       }
       .loaderMain .loaderBox{
          background: #262a2ed9;
          padding: 0px 10px;
          min-width: 200px;
          text-align: center;
          box-shadow: 0 0 5px #c7c7c7;
      }
      .loaderMain  .loader {
         display: flex;
         font-size: 15px;
         padding: 8px;
         color: #fff;
       }
       .loaderMain  .loader .dots {
         display: flex;
         position: relative;
         top: 7px;
         left: -10px;
         width: 100px;
         -webkit-animation: dots 4s ease infinite 1s;
                 animation: dots 4s ease infinite 1s;
       }
       .loaderMain  .loader .dots div {
         position: relative;
         width: 5px;
         height: 5px;
         margin-right: 10px;
         border-radius: 100%;
         background-color: white;
       }
       .loaderMain  .loader .dots div:nth-child(1) {
         width: 0px;
         height: 0px;
         margin: 5px;
         margin-right: 15px;
         -webkit-animation: show-dot 4s ease-out infinite 1s;
                 animation: show-dot 4s ease-out infinite 1s;
       }
       .loaderMain  .loader .dots div:nth-child(4) {
         background-color: transparent;
         -webkit-animation: dot-fall-left 4s linear infinite 1s;
                 animation: dot-fall-left 4s linear infinite 1s;
       }
       .loaderMain  .loader .dots div:nth-child(4):before {
         position: absolute;
         width: 5px;
         height: 5px;
         margin-right: 10px;
         border-radius: 100%;
         background-color: #767879;
         content: '';
         -webkit-animation: dot-fall-top 4s cubic-bezier(0.46, 0.02, 0.94, 0.54) infinite 1s;
                 animation: dot-fall-top 4s cubic-bezier(0.46, 0.02, 0.94, 0.54) infinite 1s;
       }
       
       @-webkit-keyframes dots {
         0% {
           left: -10px;
         }
         20%,100% {
           left: 10px;
         }
       }
       
       @keyframes dots {
         0% {
           left: -10px;
         }
         20%,100% {
           left: 10px;
         }
       }
       @-webkit-keyframes show-dot {
         0%,20% {
           width: 0px;
           height: 0px;
           margin: 5px;
           margin-right: 15px;
         }
         30%,100% {
           width: 5px;
           height: 5px;
           margin: 0px;
           margin-right: 10px;
         }
       }
       @keyframes show-dot {
         0%,20% {
           width: 0px;
           height: 0px;
           margin: 5px;
           margin-right: 15px;
         }
         30%,100% {
           width: 5px;
           height: 5px;
           margin: 0px;
           margin-right: 10px;
         }
       }
       @-webkit-keyframes dot-fall-left {
         0%, 5% {
           left: 0px;
         }
         100% {
           left: 200px;
         }
       }
       @keyframes dot-fall-left {
         0%, 5% {
           left: 0px;
         }
         100% {
           left: 200px;
         }
       }
       @-webkit-keyframes dot-fall-top {
         0%, 5% {
           top: 0px;
         }
         30%,100% {
           top: 50vh;
         }
       }
       @keyframes dot-fall-top {
         0%, 5% {
           top: 0px;
         }
         30%,100% {
           top: 50vh;
         }
       }
       
        `
    ]
})

export class  DataloaderComponent implements OnInit, OnDestroy {
    @Input('type') type = '';
    @Input('show') show = false;
    @Input('showBg') showBg = true;
    private subscription: Subscription;
    constructor(private loaderService: LoaderService) {
      this.subscription = this.loaderService.dataLoaderState
      .subscribe((state: LoaderState) => {
        console.log(state)
          this.show = state.show;
          this.type = 'text';

      });
     }
    ngOnInit() {
    }
    ngOnDestroy() {
        if(this.subscription) {
            this.subscription.unsubscribe();
        }
    }
}
