import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LoaderState } from './loader';
import { ConstantService } from './constant.service';
import { HttpClient } from '@angular/common/http';
@Injectable({
    providedIn: 'root'
})
export class McpService {
    constructor(private constant: ConstantService, private http: HttpClient) { }

    saveNewCard(payload) {
        return new Promise((resolve)=>{
            this.http.post(this.constant.BASE_URL + this.constant.MCP, payload).subscribe((resp) =>{
                resolve(resp);
            })
        })
    }

    updateMotherDetail(sys_no, payload) {
        return new Promise((resolve)=>{
            this.http.put(this.constant.BASE_URL + this.constant.MCP_MOTHER_DATA + '/sys_no/'+ sys_no + '/', payload).subscribe((resp) =>{
                resolve(resp);
            })
        })
    }
    getMCP(string) {
        return this.http.get(this.constant.BASE_URL + this.constant.CUSTOM_REPORT + string + '/');
    }
    
    // getMCPCount() {
    //     return this.http.get(this.constant.BASE_URL + this.constant.MCP_MOTHER_DATA);
    // }
    
    getMotherDetail(mcts_rch_id) {
        return this.http.get(this.constant.BASE_URL + this.constant.MCP_MOTHER_DATA + '/rchid/'+ mcts_rch_id + '/');
    }
    getMotherDetailBySysNo(sys_no) {
        return this.http.get(this.constant.BASE_URL + this.constant.MCP_MOTHER_DATA + '/sys_no/'+ sys_no + '/');
    }
    
    addBulkMotherData(payload) {
        return this.http.post(this.constant.BASE_URL + this.constant.MCP_MOTHER_DATA + 'true/', payload);
    }
}