import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantService } from './constant.service';

@Injectable()
export class CovidService {
    CSV_FILE_HEADER = {
        OUID: 'ouid',
        VEHICLE_NO: 'vehicleNo',
        DEVICE_ID: 'deviceId',
        VEHICLE_TYPE: 'vehicleType',
        SINCE: 'since',
        DRIVER_PHONE_NUMBER: 'driverPhoneNumber',
        STATUS: 'status',
        STATE: 'state',
    };
    constructor(public http: HttpClient, private constant: ConstantService) {

    }

    addNewCovidPatientDetail(payload) {
        return this.http.post(this.constant.BASE_URL + this.constant.COVID_NEW_PATIENT, payload);
    }
    addBulkPatient(payload) {
        return this.http.post(this.constant.BASE_URL + this.constant.COVID_NEW_PATIENT + 'true/', payload);
    }
    addBulkNegativePatient(payload) {
        return this.http.post(this.constant.BASE_URL + this.constant.COVID_NEGATIVE_PATIENT + 'true/', payload);
    }
    addBulkFeedback(payload) {
        return this.http.post(this.constant.BASE_URL + this.constant.COVID_PATIENT_FEEDBACK + 'true/', payload);
    }
    addNewPatientWithFeedback(payload) {
        return this.http.post(this.constant.BASE_URL + this.constant.COVID_NEW_PATIENT_WITH_FEEDBACK, payload);
    }
    addPatientFeedback(payload) {
        return this.http.post(this.constant.BASE_URL + this.constant.COVID_PATIENT_FEEDBACK, payload);
    }
    updatePatientDetail(srl_no, payload) {
        return this.http.put(this.constant.BASE_URL + this.constant.COVID_NEW_PATIENT+ 'srl_no/' + srl_no + '/', payload);
    }
    updatePatientDetailWithFeedback(srl_no, payload) {
        return this.http.put(this.constant.BASE_URL + this.constant.COVID_NEW_PATIENT_WITH_FEEDBACK+ 'srl_no/' + srl_no + '/', payload);
    }

    
    getFeedbackCountByPatient(srl_no) {
        return new Promise((resolve) => {
            let queryString = 'COUNT(*) as count FROM `covid_patient_feedback` WHERE `covid_patient_id` = ' + srl_no;
            return this.http.get(this.constant.BASE_URL + '/customsearch/' + btoa(queryString) + '/').subscribe((resp) => {
                resolve(resp);
            });
        });
    }


    getStatusReport(queryString){
        return new Promise((resolve) => {
            this.http.get(this.constant.BASE_URL + '/customsearch/' + queryString + '/').subscribe((resp) => {
                resolve(resp);
            });
        });
    }
    getDistanceReport(queryString){
        return new Promise((resolve) => {
            this.http.get(this.constant.BASE_URL + '/customsearch/' + queryString + '/').subscribe((resp) => {
                resolve(resp);
            })
        })
    }
    
    loadFeedback(srl_no){
        return new Promise((resolve) => {
            this.http.get(this.constant.BASE_URL + this.constant.COVID_PATIENT_FEEDBACK + 'covid_patient_id/'+ srl_no + '/').subscribe((resp) => {
                resolve(resp);
            })
        })
    }

    loadPatientData(mobile_no){
        return new Promise((resolve) => {
            this.http.get(this.constant.BASE_URL + this.constant.COVID_NEW_PATIENT + 'mobile_no/'+ mobile_no + '/').subscribe((resp) => {
                resolve(resp);
            })
        })
    }

    
}