import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantService } from './constant.service';

@Injectable()
export class ContactsService {
    constructor(public http: HttpClient, private constant: ConstantService) {

    }
    
    ASHA_TABLE_HEADER = [
        {headerName: 'Srl No', field: 'srl_no', sortable: true, filter: true, resizable: true, width: 100},
        {headerName: 'ASHA Name', field: 'asha_name', sortable: true, filter: true, resizable: true},
        {headerName: 'Block', field: 'block', sortable: true, filter: true, resizable: true},
        {headerName: 'Contact No', field: 'contact_no', sortable: false, filter: true, resizable: true},
        {headerName: 'Name of the G.P.', field: 'g_p_name', sortable: false, filter: true, resizable: true},
        {headerName: 'Name of the Sub-Center', field: 'sub_center_name', sortable: false, filter: true, resizable: true},
        {headerName: 'Name of the Village she belongs to', field: 'village_belonging', sortable: true, filter: true, resizable: true}
      ];

      
    AWW_TABLE_HEADER = [
        {headerName: 'Srl No', field: 'srl_no', sortable: true, filter: true, resizable: true, width: 100},
        {headerName: 'Name', field: 'name', sortable: true, filter: true, resizable: true},
        {headerName: 'Contact No', field: 'contact_no', sortable: true, filter: true, resizable: true},
        {headerName: 'Designation', field: 'designation', sortable: true, filter: true, resizable: true},
        {headerName: 'Department', field: 'department', sortable: false, filter: true, resizable: true},
        {headerName: 'AWC', field: 'awc', sortable: false, filter: true, resizable: true},
        {headerName: 'Block', field: 'block', sortable: false, filter: true, resizable: true},
        {headerName: 'Sub Division', field: 'sub_division', sortable: true, filter: true, resizable: true},
        {headerName: 'Email Id', field: 'email', sortable: false, filter: true, resizable: true},
        {headerName: 'Whatsapp No', field: 'whatsapp', sortable: false, filter: true, resizable: true},
        {headerName: 'Mobile', field: 'mobile', sortable: false, filter: true, resizable: true},
        {headerName: 'Telephone', field: 'telephone1', sortable: true, filter: true, resizable: true}
      ];

    BPMU_TABLE_HEADER = [
        {headerName: 'Srl No', field: 'srl_no', sortable: true, filter: true, resizable: true, width: 100},
        {headerName: 'Name of the Institution', field: 'institution_name', sortable: true, filter: true, resizable: true},
        {headerName: 'Name of the staff', field: 'staff_name', sortable: true, filter: true, resizable: true},
        {headerName: 'Designation', field: 'designation', sortable: true, filter: true, resizable: true},
        {headerName: 'CUG No', field: 'cug_no', sortable: false, filter: true, resizable: true},
        {headerName: 'Whatsapp No', field: 'whatsapp', sortable: false, filter: true, resizable: true},
        {headerName: 'Email Id', field: 'email_id', sortable: false, filter: true, resizable: true}
      ];
    EMERGENCY_TABLE_HEADER = [
        {headerName: 'Srl No', field: 'srl_no', sortable: true, filter: true, resizable: true, width: 100},
        {headerName: 'Department', field: 'department', sortable: true, filter: true, resizable: true},
        {headerName: 'Name of the Officeer', field: 'officer_name', sortable: true, filter: true, resizable: true},
        {headerName: 'Designation', field: 'designation', sortable: true, filter: true, resizable: true},
        {headerName: 'Landline Ofice', field: 'landline_office', sortable: false, filter: true, resizable: true},
        {headerName: 'Landline Residence', field: 'landline_residence', sortable: false, filter: true, resizable: true},
        {headerName: 'Mobile No', field: 'mobile_no', sortable: false, filter: true, resizable: true},
        {headerName: 'Fax No', field: 'fax_no', sortable: false, filter: true, resizable: true},
        {headerName: 'Email Id', field: 'email_id', sortable: false, filter: true, resizable: true}
      ];

    WDC_TABLE_HEADER = [
        {headerName: 'Srl No', field: 'srl_no', sortable: true, filter: true, resizable: true, width: 100},
        {headerName: 'Name of the staff', field: 'name', sortable: true, filter: true, resizable: true},
        {headerName: 'Designation', field: 'designation', sortable: true, filter: true, resizable: true},
        {headerName: 'Department', field: 'department', sortable: false, filter: true, resizable: true},
        {headerName: 'Block', field: 'block', sortable: false, filter: true, resizable: true},
        {headerName: 'Sub Division', field: 'sub_division', sortable: false, filter: true, resizable: true},
        {headerName: 'Whatsapp No', field: 'whatsapp', sortable: false, filter: true, resizable: true},
        {headerName: 'Mobile', field: 'mobile', sortable: false, filter: true, resizable: true},
        {headerName: 'Telephone No', field: 'telephone1', sortable: false, filter: true, resizable: true},
        {headerName: 'Telephone No', field: 'telephone2', sortable: false, filter: true, resizable: true},
        {headerName: 'Email Id', field: 'email_id', sortable: false, filter: true, resizable: true}
      ];
    getAwwList() {
        return this.http.get(this.constant.BASE_URL + this.constant.AWW);
    }    
    getWdcList() {
        return this.http.get(this.constant.BASE_URL + this.constant.WDC_STAFF);
    }
    getAshaList() {
        return this.http.get(this.constant.BASE_URL + this.constant.ASHA_WORKER);
    }
    getBpmuList() {
        return this.http.get(this.constant.BASE_URL + this.constant.BPMU_STAFF);
    }
    getEmergencyList() {
        return this.http.get(this.constant.BASE_URL + this.constant.EMERGENCY_CONTACTS);
    }
}