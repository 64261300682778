import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConstantService } from './constant.service';

@Injectable()
export class NewsService {
    DEPARTMENT = [];


    constructor(public http: HttpClient, private constant: ConstantService) {

    }
    addnews(payload: any, file) {

        let fd = new FormData();
        fd.append('news', payload.news);
        fd.append('date', payload.date);
        fd.append('file', file)
        return this.http.post<any>(this.constant.BASE_URL + this.constant.NEWS_UPLOAD_PATH, fd);
    }
    listNews() {
        return this.http.get(this.constant.BASE_URL + this.constant.NEWS);
    }
}