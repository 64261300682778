import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { LoginService } from '../../services/login.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html',
  styleUrls: ['login.component.css']
})
export class LoginComponent implements OnInit { 
  userCred: any = {
    username: '',
    password: ''
  }
  errorMsg = '';
  flag = {
    loading: false,
    login: true,
    passwordReseted: false,
    resetPassword: true
  }
  forgot = {
    emailId: ''
  }
  constructor(private router: Router, private loginService: LoginService, private activatedRoute: ActivatedRoute) { }

  login() {
    this.errorMsg = '';
    this.flag.loading = true;
    let that = this;
    this.userCred.password = btoa(this.userCred.password);
    this.loginService.login(this.userCred).subscribe((loginResp: any) => {
      // console.log(loginResp);
      if (loginResp.token) {
        //  this.flag.loading = false;
        //  let cookies = atob(loginResp.token);
        //  let respArray = cookies.split('@');
        //  console.log(respArray);
        //  console.log(respArray[0], atob(respArray[1]), atob(respArray[2]), atob(respArray[3]));
        // sessionStorage.token = btoa(atob(respArray[1]) +':'+ respArray[0]);
        sessionStorage.token = loginResp.token;
        
        this.router.navigate(['views', 'dashboard']);
      }
     
    }, (err) => {
      console.log(err.error);
      this.flag.loading = false;
      this.errorMsg = err.error.error;
      setTimeout(() => {
        this.errorMsg = '';
      }, 1500)
    })
    
  }
  
  ngOnInit(): void {
    
  }

  resetPassword() {
    
    this.loginService.resetPassword(this.forgot.emailId).subscribe((response) => {
      console.log(response);
      if (response && response['msg']){
        this.flag.passwordReseted = true;
        this.flag.resetPassword = false;
      }
      if (response && response['error']){
        this.errorMsg = "Email id is not registered with Call Center Sundargarh. Please contact admin."
      }
    })
  }

}
