import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { ProfileService } from './services/profile.service';

@Component({
  // tslint:disable-next-line
  selector: 'body',
  template: '<router-outlet></router-outlet>  <app-data-loader class="overlayFix"></app-data-loader>'
})
export class AppComponent implements OnInit {
  constructor(private router: Router, private profileService: ProfileService) { }

  ngOnInit() {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
    // this.profileService.setDepartment();
  }
}
