import {Injectable} from "@angular/core";
import {Router} from "@angular/router";
import {
  HttpHandler,
  HttpHeaderResponse, HttpInterceptor,
  HttpProgressEvent,
  HttpRequest, HttpResponse,
  HttpSentEvent, HttpUserEvent
} from '@angular/common/http';
import {Observable} from "rxjs/internal/Observable";

import {tap} from "rxjs/operators";
import {HttpEvent} from "../../../node_modules/@angular/common/http";
import { LoaderService } from './loader.service';
import { ConstantService } from './constant.service';

@Injectable({
    providedIn: 'root'  // <- ADD THIS
})
export class Interceptor implements HttpInterceptor {

   private totalRequests = 0;

  constructor( private router: Router, private loaderService: LoaderService, private constant: ConstantService) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler) :
    Observable<HttpSentEvent | HttpHeaderResponse | HttpProgressEvent | HttpResponse<any> | HttpUserEvent<any>> {
      let authRequest = req;
      let tokenData: any = this.constant.getCookies();
      let token = tokenData.USERNAME + ':' + tokenData.TOKEN;
      if (sessionStorage.token != null && this.isUrlExternalUrlRequest(req)) {
        authRequest = req.clone({ headers: req.headers.set('Authorization', btoa(token))})
      }
      if (!(req.url.indexOf('/loginValidate') > -1)) {
          this.totalRequests++;
          this.showLoader();
      }


      return next.handle(authRequest)
      .pipe(
          tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
              this.decreaseRequests();
              }
              },
              (err: any) => {
                  console.log(err.error);
                  if (err.status === 401) {
                    this.totalRequests = 0;
                    this.router.navigate(['login']);
                  }
                  
                this.onEnd();
                this.decreaseRequests();
                
                return err.error;
              })
      );
  }
  private isUrlExternalUrlRequest(req: HttpRequest<any>):boolean{
    return (req.url.indexOf("maps.google.com") == -1 );
  }

  
  private onEnd(): void {
    this.hideLoader();
}
private showLoader(): void {
    this.loaderService.showDataLoader();
}
private hideLoader(): void {
    this.loaderService.hideDataLoader();
}
private decreaseRequests() {
    this.totalRequests--;
    if (this.totalRequests <= 0) {
        this.onEnd();
    }
}
}
